import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';

function LetterBox(props) {
  const classes = useStyles();  

  const [isMobile, setIsMobile] = useState(undefined);
  if (isMobile === undefined) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        setIsMobile(true);
    }
  }

  return (
    <Box className={[classes.letterBoxContainer, isMobile && classes.letterBoxContainerMobile, props.type === 'guess' && classes.guessLetterBox, props.type === 'hint' && classes.hintLetterBox || props.type === 'fail' && classes.letterBoxFail || props.type === 'success' && classes.letterBoxSuccess]} style={{visibility: props.invisible && 'hidden'}}>
      <div>{props.letter}</div>
    </Box>
  );
}

export default LetterBox;
