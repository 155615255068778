export function RegisteredInputs() {
    return [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'Enter',
        'Backspace'
    ]
}

export function StringNumbersToEmojis(string) {
    var splittedString = string.split("");
    var formattedString = ''
    splittedString.forEach(letter => {
        switch (letter) {
            case "0":
                formattedString += "0️⃣"
                break;
            case "1":
                formattedString += "1️⃣"
                break;
            case "2":
                formattedString += "2️⃣"
                break;
            case "3":
                formattedString += "3️⃣"
                break;
            case "4":
                formattedString += "4️⃣"
                break;
            case "5":
                formattedString += "5️⃣"
                break;
            case "6":
                formattedString += "6️⃣"
                break;
            case "7":
                formattedString += "7️⃣"
                break;
            case "8":
                formattedString += "8️⃣"
                break;
            case "9":
                formattedString += "9️⃣"
                break;
        }
    });

    return formattedString;
}

export function IsHintLetter(hintLetters, letter) {
    var flag = false;
    hintLetters.forEach((hLetter) => {
        console.log(hLetter, letter);
        if (hLetter?.toString().toLowerCase() === letter?.toString().toLowerCase()) {
            flag = true
        }
    })
    return flag;
}

export function RecursiveGetHintLetter(hintLetters, answer) {
    var newReveal = answer[Math.floor(Math.random() * answer.length)].toString();
    var flag = false;
    hintLetters.forEach((hLetter) => {
        if (hLetter.toString().toLowerCase() === newReveal.toString().toLowerCase()) {
            flag = true;
        }
    })
    if (flag) {
        console.log("Recursive, looping");
        return RecursiveGetHintLetter(hintLetters, answer);
    } else {
        console.log("Output: ", newReveal);
        return newReveal;
    }
    /* if (hintLetters.includes(newReveal)) {
        RecursiveGetHintLetter(hintLetters, answer);
    } else {
        return newReveal;
    } */
}