import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  appbarContainer: {
    backgroundColor: '#FAF5E2 !important',
    color: '#32312C !important',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  }
});
