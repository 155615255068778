import { FacebookOutlined } from "@mui/icons-material";
import moment from "moment";
import React, { useState } from "react";
import { useStyles } from "./styles";

function Countdown(props) {
  const classes = useStyles();

  const [counter, setCounter] = useState();

  setTimeout(() => {
    setCounter(moment("2022-03-02 12:00:00.000").fromNow());
  }, 1000);

  const handleFacebookClick = () => {
    window.open(
      "https://www.facebook.com/studioatlasofficial",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      {/* <div className={classes.updateReminderText}>
      We've been having issues, <span className={classes.updateReminderTextCounter}>but things should be back to normal.</span>
    </div> */}
      <div
        className={classes.followFacebookWrapper}
        onClick={handleFacebookClick}
      >
        <span className={classes.updateReminderTextCounter}>
          <FacebookOutlined />
        </span>{" "}
        Follow us on Facebook
      </div>
    </>
  );
}

export default Countdown;
