import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  numberContainer: {
    width: 75,
    height: 75,
    backgroundColor: '#FAF5E2',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 5,
  },
  numberText: {
    fontSize: 35,
    fontWeight: 'bold',
  },
  titleText: {
    fontSize: 12,
    color: '#C8C3B4'
  },
});
