import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  updateReminderText: {
    marginBottom: 20,
  },
  updateReminderTextCounter: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  followFacebookWrapper: {
    marginBottom: 20,
    marginTop: 20,
    cursor: "pointer",
  },
});
