import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  riddleGuesserContainer: {
    width: '100%',
    display: 'flex',    
    alignItems: 'flex-start',
    flexDirection: 'column-reverse'
  },
  riddleGuesserRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px !important',    
  },
  incorrectGuessesContainer: {
    display: 'flex',
    flexDirection: 'column-reverse !important'
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
