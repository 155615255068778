import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    height: '92vh',
    width: '100%',
    marginTop: '10px',    
  },
  mainGridWrapper: {
    height: '100%',
    width: '100%',    
  },
  updateReminderText: {
    color: 'rgba(50,49,44,0.25) !important',
    fontSize: 14,
    marginBottom: 10,
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hintsContainer: {
    display: 'flex',
    flexDirection: 'column-reverse !important'
  },
  hintText: {
    color: "#E3C000 !important",
    fontSize: 20,    
  },
  mobileText: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileInput: {
    marginTop: 10,
  },
  adWrapper: {
    height: '100% !important',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});
