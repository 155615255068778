import { Box, Grid, Item, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AdSense from 'react-adsense';
import Countdown from '../../components/Countdown/Countdown';
import FinishedModal from '../../components/FinishedModal/FinishedModal';
import RiddleContainer from '../../components/RiddleContainer/RiddleContainer';
import RiddleGuesser from '../../components/RiddleGuesser/RiddleGuesser';
import { useStyles } from './styles';
import ReactGA from 'react-ga';
import { RecursiveGetHintLetter } from '../../utils/tools';
import StatisticsModal from '../../components/StatisticsModal/StatisticsModal';
import FooterBanner from '../../components/FooterBanner/FooterBanner';

function Home(props) {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const classes = useStyles();    

    const [finishModalOpen, setFinishModalOpen] = useState(false);
    const handleFinishModalOpen = (lockout) => {
        setModalContent(lockout)
        setFinishModalOpen(true);
    }
    const handleFinishModalClose = () => setFinishModalOpen(false);

    const [hintLetters, setHintLetters] = useState([]);

    const [modalContent, setModalContent] = useState()

    const [playerData, setPlayerData] = useState();
    if (playerData === undefined) {
        if (props.storedPlayerData === null) {
            const defaultData = {
                "overview": {
                    "lastRiddleId": 0,
                    "dailyStreak": 0,
                    "maxStreak": 0,
                    "totalSuccess": 0,
                    "totalFail": 0,
                    "guessMap": {
                        "1": 0,
                        "2": 0,
                        "3": 0,
                        "4": 0,
                        "5": 0
                    },
                    "hintMap": {
                        "0": 0,
                        "1": 0,
                        "2": 0,
                        "3": 0,
                    }
                },
                "history": [
                ]
            }
            setPlayerData(defaultData)
            localStorage.setItem('playerData', JSON.stringify(defaultData));
        } else {
            setPlayerData(props.storedPlayerData);
            localStorage.setItem('playerData', JSON.stringify(props.storedPlayerData));
        }
    }

      const riddleSchema = {
          id: props.currentRiddle.id,
          riddle: props.currentRiddle.riddle,
          answer: props.currentRiddle.answer,
          hints: [
              "",
              "",
              ""
          ],
          allowedGuesses: props.currentRiddle.allowedGuesses,

      }

      const currentRiddle = localStorage.getItem('currentRiddle');
      if (currentRiddle === null || currentRiddle !== riddleSchema.riddle) {
          localStorage.clear()
          localStorage.setItem('currentRiddle', riddleSchema.riddle);
      }

      var gameHints = {
        currentHints: [],
        hintsRemain: riddleSchema.hints.length
      }

      const [attempts, setAttempts] = useState(0);
      const [hintsRemain, setHintsRemain] = useState(riddleSchema.hints.length) 
      const [currentHints, setCurrentHints] = useState([])

      const handleUseHint = () => {
          if (hintsRemain >= 1) {
              setHintsRemain(hintsRemain - 1)
              setCurrentHints(currentHints => [...currentHints, riddleSchema.hints[hintsRemain - 1]])  
              var newHint = [...currentHints, riddleSchema.hints[hintsRemain - 1]]
              
              gameHints.hintsRemain = hintsRemain - 1;
              gameHints.currentHints = newHint
              updateLocalStorage()
          } else {
              console.log("No more hints")
          }
      }

      const handleRevealLetter = () => {
          if (hintsRemain >= 1) {
              setHintsRemain(hintsRemain - 1);
              var newReveal = RecursiveGetHintLetter(hintLetters, riddleSchema.answer)
              setHintLetters(hintLetters => [...hintLetters, newReveal]);
              setCurrentHints(currentHints => [...currentHints, newReveal])  
              var newHint = [...currentHints, newReveal]
              gameHints.hintsRemain = hintsRemain - 1;
              gameHints.currentHints = newHint
              updateLocalStorage()              
          }
      }

      const updateLocalStorage = () => {
          localStorage.setItem("gameHints", JSON.stringify(gameHints))
      }

      useEffect(() => {
        if (localStorage.getItem('gameHints') !== null) {                  
            gameHints = JSON.parse(localStorage.getItem('gameHints'))
            setHintLetters(gameHints.currentHints);
            setHintsRemain(gameHints.hintsRemain)
            setCurrentHints(gameHints.currentHints)
        }
      }, []);

      const handleSetPlayerData = (data) => {
          const { success, guesses } = data;
          var temp = {...playerData};

          // Update success or fail
          if (success) {
            temp.overview.totalSuccess++;

            // Update Daily Streak
            if (riddleSchema.id - 1 === temp.overview.lastRiddleId) {
                // increment if streak
                temp.overview.dailyStreak++;
                // Set new max streak if there is one
                if (temp.overview.dailyStreak > temp.overview.maxStreak) {
                    temp.overview.maxStreak = temp.overview.dailyStreak;
                }
            } else {
                // Reset streak if new streak
                temp.overview.dailyStreak = 0;
            }

            // Update Max Streak
          } else {
            temp.overview.totalFail++;

            // Reset Daily Streak
            temp.overview.dailyStreak = 0;
          }

          // Set the new lastRiddleId
          temp.overview.lastRiddleId = riddleSchema.id;

          // Update Guess Map
          temp.overview.guessMap[guesses]++;

          // Update Hint Map
          temp.overview.hintMap[currentHints.length]++;          

          // Update History
          temp.history.push({
              "riddleId": riddleSchema.id,
              "success": success,
              "guesses": guesses,
              "hints": currentHints.length,
          });

          localStorage.setItem('playerData', JSON.stringify(temp));
      }

      const [isMobile, setIsMobile] = useState(undefined);
      if (isMobile === undefined) {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setIsMobile(true);
        }
      }

  return (
    <div className={classes.root}>
    { true ? (
        <>
        <Modal
            open={finishModalOpen}
            onClose={handleFinishModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <FinishedModal modalContent={modalContent} hintsRemain={hintsRemain} answer={riddleSchema.answer} handleFinishModalClose={handleFinishModalClose} />
        </Modal>  
        <Modal
            open={props.statisticsModalOpen}
            onClose={() => {
                props.setStatisticsModalOpen(!props.statisticsModalOpen);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <StatisticsModal playerData={playerData} handleStatisticsModalClose={()=>{props.setStatisticsModalOpen(false)}} />
        </Modal> 
        <Countdown />
        <FooterBanner />
        <Grid container spacing={3} className={classes.mainGridWrapper}>
            <Grid item xs={2} className={classes.adWrapper}>
            {/* Ad Banner */}
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} direction={'column'} className={classes.mainGridWrapper} wrap="nowrap">
                    <Grid item xs={3}>
                        <RiddleContainer riddleSchema={riddleSchema} allowedGuesses={riddleSchema.allowedGuesses} attempts={attempts} hintsRemain={hintsRemain} handleUseHint={handleRevealLetter} />
                    </Grid>
                    <Grid item xs={6}>
                        <RiddleGuesser handleSetPlayerData={handleSetPlayerData} answer={riddleSchema.answer} allowedGuesses={riddleSchema.allowedGuesses} attempts={attempts} setAttempts={setAttempts} handleFinishModalOpen={handleFinishModalOpen} hintLetters={hintLetters}/>
                    </Grid>
                    <Grid item xs={3} className={classes.adWrapper}>
                        {/* {currentHints.map((hint, index) => {
                            return (
                                <>
                                <hr></hr>
                                <div className={classes.hintText}>{hint}</div>
                                </>
                            )
                        })} */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} className={classes.centerContent}>
                {/* <div>Ad Space</div> */}
            </Grid>
        </Grid>
    </>
    ) : (
        <div className={classes.mobileText}>Riddle Today isn't yet on mobile, try playing on desktop.</div>
    )}
</div>
  );
}

export default Home;
