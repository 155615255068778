import { Box, Grid } from '@mui/material';
import React from 'react';
import NumberContainer from './NumberContainer/NumberContainer';
import { useStyles } from './styles';

function RiddleContainer(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.main}>
      <Grid item xs={12}>
          <Box sx={{ boxShadow: 2 }} className={classes.riddleContainer}>{`"${props.riddleSchema.riddle}"`}</Box>
      </Grid>
      <Grid item xs={12} className={classes.numbersWrapper}>
        <NumberContainer number={props.allowedGuesses - props.attempts} title="Guesses Left" />
        <NumberContainer number={props.hintsRemain} title="Reveals Left" handleUseHint={props.handleUseHint} />
      </Grid>
    </Grid>
  );
}

export default RiddleContainer;
