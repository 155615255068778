import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  footerBannerWrapper: {
    width: '100%',
    marginBottom: 20,
    fontSize: 12,
    fontStyle: 'italic'
  },
  logoText: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
});
