import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  main: {
    height: '100%',
    width: '100% !important',
  },
  riddleContainer: {
    backgroundColor: '#FAF5E2',
    minHeight: 100,
    height: 'fit-content',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    color: '#32312C',
    fontSize: 25,
  },
  numbersWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileInput: {
    marginTop: 10,
  },
  mobileInputWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});
