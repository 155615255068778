import React from 'react';

function Home() {
  return (
    <div className="root">
      To do, add a help page
    </div>
  );
}

export default Home;
