import { CloseRounded, ShareOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { StringNumbersToEmojis } from '../../utils/tools';
import LetterBox from '../RiddleGuesser/LetterBox/LetterBox';
import { useStyles } from './styles';

function FinishedModal(props) {
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(undefined);
  if (isMobile === undefined) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        setIsMobile(true);
    }
  }

  var guessEmojis = ""
  for (var i = 0; i < props.modalContent.guesses; i++) {
    if (i === props.modalContent.guesses - 1) {
      // success or fail on last index
      guessEmojis += props.modalContent.success ? "🟢" : "🔴";
    } else {
      guessEmojis += "🔴";
    }
  }

  var hintEmojis = ""
  if (props.hintsRemain === 3) {
    hintEmojis = props.modalContent.success ? "None 😁" : "None 🤔";
  } else {
    for (var i = 0; i < 3 - props.hintsRemain; i++) {
      hintEmojis += "🔵";
    }
  }
  var storedPlayerData = JSON.parse(localStorage.getItem("playerData"));
  
  var formattedDailyStreak = StringNumbersToEmojis(storedPlayerData.overview.dailyStreak.toString());
  var formattedMaxStreak = StringNumbersToEmojis(storedPlayerData.overview.maxStreak.toString());

  /* const finishedText = props.modalContent.success ? `I got todays riddle in ${props.modalContent.guesses} guesses and ${3 - props.hintsRemain} hints. Let's see how you do: https://riddle.today` : `I couldn't guess todays riddle :( Let's see how you do: https://riddle.today`; */
  const finishedText = props.modalContent.success ? `https://riddle.today ${moment().format("dddd Do MMMM YYYY")}: Guesses: ${guessEmojis} | Hints: ${hintEmojis} | Daily Streak: ${formattedDailyStreak} | Max Streak: ${formattedMaxStreak}` : `I couldn't guess todays riddle :( Let's see how you do: https://riddle.today`;
  const successMessage = props.modalContent.success ? `Guesses: ${guessEmojis} | Hints: ${hintEmojis} | Daily Streak: ${formattedDailyStreak} | Max Streak: ${formattedMaxStreak}` : ``;

  return (
    <Box className={isMobile ? classes.mobileFinishedModal : classes.finishedModal} sx={{ boxShadow: 2 }}>
    <Grid container spacing={1} direction={'column'} wrap="nowrap" className={classes.gridWrapper}>
        <div className={classes.closeWrapper}><CloseRounded onClick={()=>{
          props.handleFinishModalClose()
        }} /></div>
        <Grid item xs={3} className={classes.centerContent}>
            <Typography id="modal-modal-title" variant="h4" component="h4">
                {props.modalContent.success ? "You did it!" : "Better luck next time"}
            </Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography id="modal-modal-description" variant="h6" component="h6">
              Guesses: {guessEmojis}
            </Typography>
            <Typography id="modal-modal-description" variant="h6" component="h6">
                Hints: {hintEmojis}
            </Typography>
        </Grid>
        <Grid item xs={3} className={classes.centerLetters}>
          {props.answer.split("").map((letter, index) => {
            if (letter !== " ") {
              return <LetterBox letter={letter} type={props.modalContent.success ? 'success' : 'fail'} />
            } else {
              return <LetterBox invisible={true} />
            }
          })}
        </Grid>
        {props.modalContent.success && (
        <Grid item xs={3}>        
          <Typography id="modal-modal-description" variant="h6" component="h6">
            {successMessage}
          </Typography>
        </Grid>
        )}
        <Grid item xs={3}>
            <Typography id="modal-modal-description" variant="h6" component="h6">
            {props.modalContent.success ? "Now show everyone how well you did, and challenge them to do better." : "Now show your friends how difficult the riddle was today."}
            </Typography>
        </Grid>
        <Grid item xs={3}>
          <CopyToClipboard text={finishedText} onCopy={()=>{alert("Copied! Paste it anywhere.")}}>
            <Button variant="outlined" startIcon={<ShareOutlined />}>
              Copy Results
            </Button>
          </CopyToClipboard>
        </Grid>
        <Grid item xs={3}>
          <a href='https://ko-fi.com/N4N1BMXQI' target='_blank'><img style={{height: '50px', border: '0px'}} src='https://cdn.ko-fi.com/cdn/kofi2.png?v=3' alt='Buy Me a Coffee at ko-fi.com' /></a>
        </Grid>
    </Grid>
</Box>
  );
}

export default FinishedModal;
