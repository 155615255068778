import moment from "moment";
import React, { useState } from "react";
import { useStyles } from "./styles";

function FooterBanner(props) {
  const classes = useStyles();
  return (
    <div className={classes.footerBannerWrapper}>
      <div>Created by <span className={classes.logoText}><a href="https://studioatlas.co.uk" target="_blank">STUDIOATLAS</a></span></div>
    </div>
  );
}

export default FooterBanner;
