import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  letterBoxContainer: {
    width: 50,
    height: 50,
    backgroundColor: '#FAF5E2',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: 5,
    boxShadow: 'inset 2px 3px 6px #cccccc',
    marginRight: 10,
    fontWeight: 'bold',
    fontSize: 30
  },
  letterBoxContainerMobile: {
    width: 25,
    height: 25,
    fontSize: 15
  },
  guessLetterBox: {
    backgroundColor: '#FF9393',
    opacity: 0.50,
  },
  letterBoxFail: {
    backgroundColor: '#E3C000 !important',
  },
  letterBoxSuccess: {
    backgroundColor: '#4BC300 !important',
    color: 'white',
  },
  hintLetterBox: {
    position: 'absolute',
    backgroundColor: '#4BC300',
    opacity: 0.2,
    color: 'white',
  }
});
