import './App.css';
import React, { useEffect, useState } from 'react';
import Header from './components/Header/Header';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { HelpRounded, HomeMaxRounded, HomeRounded } from '@mui/icons-material';
import Home from './pages/Home/Home';
import Help from './pages/Help/Help';
import ReactGA from 'react-ga';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);

  const [currentPath, setCurrentPath] = useState('loading');
  const [statisticsModalOpen, setStatisticsModalOpen] = useState(false);
  const [currentRiddle, setCurrentRiddle] = useState();

  useEffect(() => {
    axios.get(`https://riddletodaybackend.herokuapp.com/get_current_riddle`).then(res => {
      setCurrentRiddle(res.data[0]);
      setCurrentPath('home');
    });
  }, []);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  var storedPlayerData = JSON.parse(localStorage.getItem("playerData"));

  // Side Navigation List
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem
          button
          key="home"
          onClick={()=>{
            setCurrentPath('home');
          }}>
          <ListItemIcon>
            <HomeRounded />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          key="help"
          onClick={()=>{
            window.open('https://studioatlas.co.uk', '_blank');
          }}>
          <ListItemIcon>
            <HelpRounded />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div className="App">
      {/* Side Navigation */}
      {['left',].map((anchor) => (
        <React.Fragment key={anchor}>
          <Header handleOnClick={toggleDrawer(anchor, true)}  statisticsModalOpen={statisticsModalOpen} setStatisticsModalOpen={setStatisticsModalOpen}  />
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      {/* Page Routes */}
      {currentPath === 'home' && <Home storedPlayerData={storedPlayerData} statisticsModalOpen={statisticsModalOpen} setStatisticsModalOpen={setStatisticsModalOpen} currentRiddle={currentRiddle} />}    
      {currentPath === 'loading' && (
        <div className="loader-wrapper">
          <ClipLoader color={"#32312C"} loading={true} size={50} />
        </div>
      )}    
      {currentPath === 'help' && <Help />}      
    </div>
  );
}

export default App;
