import { Box } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useStyles } from './styles';

function NumberContainer(props) {
  const classes = useStyles();

  return (
    <Box sx={{ boxShadow: 2 }} className={classes.numberContainer} onClick={props.handleUseHint} style={{cursor: props.title === 'Reveals Left' ? 'pointer' : ''}}>
      <div className={classes.numberText} style={{color: props.number >= 3 && '#8BCC73' || props.number >= 1 && '#E3C000' || props.number === 0 && '#FF9595'}}>{props.number}</div>
      <div className={classes.titleText}>{props.title}</div>
    </Box>
  );
}

export default NumberContainer;
