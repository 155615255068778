import { CloseRounded } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { VictoryPie } from 'victory';
import { useStyles } from './styles';

function StatisticsModal(props) {
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(undefined);
  if (isMobile === undefined) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        setIsMobile(true);
    }
  }

  var chartData = []
  if (props.playerData.overview.totalSuccess > 0) {
    chartData.push({ x: "Success", y: props.playerData.overview.totalSuccess })
  }
  if (props.playerData.overview.totalFail > 0) {
    chartData.push({ x: "Failure", y: props.playerData.overview.totalFail })
  }
  
  return (
    <Box className={isMobile ? classes.mobileFinishedModal : classes.finishedModal} sx={{ boxShadow: 2 }}>
    <Grid container spacing={1} direction={'column'} wrap="nowrap" className={classes.gridWrapper}>
      <div className={classes.closeWrapper}><CloseRounded onClick={()=>{
          props.handleStatisticsModalClose()
        }} /></div>
        <Grid item xs={3} className={classes.centerContent}>
          <Typography id="modal-modal-title" variant="h4" component="h4">
              Your Statistics
            </Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography id="modal-modal-description" variant="h6" component="h6">
              {`🔥 Daily Streak: ${props.playerData.overview.dailyStreak}`}
            </Typography>
            <Typography id="modal-modal-description" variant="h6" component="h6">
              {`🏆 Max Streak: ${props.playerData.overview.maxStreak}`}
            </Typography>
        </Grid>
        <Grid item xs={3}>
          <VictoryPie
              colorScale={chartData[0]?.x === 'Failure' ? ["#feb7b7"] : ["#a3cea3", "#feb7b7"]}
              data={chartData}
              padAngle={({ datum }) => datum.y}
              innerRadius={100}
              labelRadius={({ innerRadius }) => innerRadius + 5 }
              labels={({ datum }) => `${datum.x}: ${datum.y}`}
              style={{labels: { fontSize: 20, fontWeight: "bold" }}}
          />
        </Grid>
    </Grid>
</Box>
  );
}

export default StatisticsModal;
