import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  finishedModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 800,
    backgroundColor: '#FAF5E2',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    textAlign: 'center',
  },
  mobileFinishedModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    backgroundColor: '#FAF5E2',
    borderRadius: 10,
    padding: 10,
    display: 'flex',
    textAlign: 'center',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerLetters: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeWrapper: {
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  gridWrapper: {
    padding: '20px'
  }
});
