import { Button, Grid, IconButton, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import LetterBox from './LetterBox/LetterBox';
import { useStyles } from './styles';
import { IsHintLetter, RegisteredInputs } from '../../utils/tools';
import { SearchOutlined, SendRounded } from '@mui/icons-material';

function RiddleGuesser(props) {
  const classes = useStyles();

  var inputField = document.getElementById("mobile-input")

  const [currentGuess, setCurrentGuess] = useState('');

  const [incorrectGuesses, setIncorrectGuesses] = useState([]); 
  const [lockout, setLockout] = useState({status: false, success: undefined});
  const [guessLengthMatch, setGuessLengthMatch] = useState(false);

  var gameState = (localStorage.getItem('gameState') === null ? {
    incorrectGuesses: [],
    lockout: {
      status: false,
      success: undefined,
    }
  } : JSON.parse(localStorage.getItem('gameState')));  

  const updateLocalStorage = (finalGuess) => {
    gameState.incorrectGuesses.push(finalGuess);
    localStorage.setItem("gameState", JSON.stringify(gameState))
  }

  const updateLocalStorageFromLockout = (lockout) => {        
    gameState.lockout = lockout;
    localStorage.setItem("gameState", JSON.stringify(gameState))
  }

  const handleSubmit = () => {
    const finalGuess = currentGuess.toLowerCase()
    const finalAnswer = props.answer.toLowerCase()

    // If currentguess is the answer
    if (finalGuess === finalAnswer) {
      // if success
      setIncorrectGuesses(incorrectGuesses => [...incorrectGuesses, finalGuess])
      props.setAttempts(props.attempts + 1)
      setCurrentGuess("")
      setLockout({status: true, success: true})
      updateLocalStorage(finalGuess)
      updateLocalStorageFromLockout({status: true, success: true, guesses: props.attempts + 1})
      props.handleFinishModalOpen({status: true, success: true, guesses: props.attempts + 1})
      props.handleSetPlayerData({success: true, guesses: props.attempts + 1})
      inputField.blur()
    } else {
      // If last guess
      if (incorrectGuesses.length === props.allowedGuesses - 1) {        
        setIncorrectGuesses(incorrectGuesses => [...incorrectGuesses, finalGuess])
        props.setAttempts(props.attempts + 1)
        setCurrentGuess("")
        setLockout({status: true, success: false})
        updateLocalStorage(finalGuess)
        updateLocalStorageFromLockout({status: true, success: false, guesses: props.attempts + 1})
        props.handleFinishModalOpen({status: true, success: false, guesses: props.attempts + 1})
        props.handleSetPlayerData({success: false, guesses: props.attempts + 1})
        inputField.blur()
      } else {
        // if normal guess
        setIncorrectGuesses(incorrectGuesses => [...incorrectGuesses, finalGuess])
        props.setAttempts(props.attempts + 1)
        setCurrentGuess("")
        updateLocalStorage(finalGuess)
      } 
    }
  }

  useEffect(() => {
    if (localStorage.getItem('gameState') !== null) {      
      // Set the current game state
      gameState = JSON.parse(localStorage.getItem('gameState'))
      setLockout(gameState.lockout)
  
      // set incorrect guesses and attempts from gamestate
      setIncorrectGuesses(gameState.incorrectGuesses)
      props.setAttempts(gameState.incorrectGuesses.length)
  
      // Upate the guess amount of game is finished
      if (gameState.lockout.status){
        // Show finish modal
        props.handleFinishModalOpen(gameState.lockout)
      }
    }
  },[])

  const [isMobile, setIsMobile] = useState(undefined);
  if (isMobile === undefined) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        setIsMobile(true);
    }
  }

  const finalGuessLength = currentGuess.toLowerCase().length;
  const finalAnswerLength = props.answer.toLowerCase().length;

  if (finalGuessLength >= finalAnswerLength) {
    if (!guessLengthMatch) {
      setGuessLengthMatch(true)
    }
  } else {
    if (guessLengthMatch) {
      setGuessLengthMatch(false)
    }
  }

  const handleSubmitGuess = () => {
    if (!gameState.lockout.status) {
      handleSubmit()
    }
  }

  const handleNewInput = (input) => { 
    if (input.length <= props.answer.length) {
      setCurrentGuess(input);
    }
    /* if (input.length > newCurrentGuess.length && props.answer.split("")[newCurrentGuess.length + 1] === " ") {
      console.log("First fired...")
      var test = input += " "
      setNewCurrentGuess(test)   
    } else if (input.length < newCurrentGuess.length && props.answer.split("")[newCurrentGuess.length - 2] === " ") {
      var test = input.replaceAll(" ", "");
      setNewCurrentGuess(test)
    } */
  }
  
  return (
    <>
    {!lockout.status && (
      <div className={classes.inputWrapper}>
        <TextField
            label="Enter guess here"
            id="mobile-input"
            defaultValue=""
            value={currentGuess}
            variant="filled"
            size="small"
            autoComplete="off"
            onChange={e => handleNewInput(e.target.value)}    
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmitGuess()
              }
            }}        
          />
          {guessLengthMatch && (
            <IconButton color="primary" aria-label="Submit guess" onClick={handleSubmitGuess}>
              <SendRounded />
          </IconButton>  
          )}        
      </div>
    )}
    <Grid container className={classes.riddleGuesserContainer}>
      <Grid item xs={12} className={classes.riddleGuesserRow}>
        {!lockout.status ? (
        <>
          {props.answer.split("").map((letter, index) => {
            if (letter !== " ") {          
              if (IsHintLetter(props.hintLetters, letter)) {
                return (
                  <div style={{display: 'flex'}}>
                    {/* Guess Letter */}
                    <LetterBox letter={currentGuess.charAt(index)} />
                    {/* Hint Letter */}
                    <LetterBox letter={letter} type="hint" />
                  </div>
                )
              } else {
                return <LetterBox letter={currentGuess.charAt(index)}/>
              }
            } else {
              return <LetterBox invisible={true} />
            }
          })}
        </>
        ) : (
          <>
          {props.answer.split("").map((letter, index) => {
            if (letter !== " ") {
              return <LetterBox letter={letter} type={lockout.success ? 'success' : 'fail'} />
            } else {
              return <LetterBox invisible={true} />
            }
          })}
          </>
        )}
      </Grid>
    </Grid>
    <Grid container className={classes.incorrectGuessesContainer}>
      {incorrectGuesses.map((guess, index) => {
        // Only output guesses that aren't the answer.        
        if (guess.toLowerCase() !== props.answer.toLowerCase()) {
          return (
            <Grid item xs={12}  className={classes.riddleGuesserRow}>
              {/* Output each letter individually */}
              {guess.split("").map((letter, index) => {
                if (letter !== " ") {
                  return <LetterBox letter={letter} type="guess" />
                } else {
                  return <LetterBox invisible={true} />
                }
              })}
            </Grid>)
        }        
        }
      )}
    </Grid>
  </>
    
  );
}

export default RiddleGuesser;
